export default {
    QueryDoCityResult: state => state.common.QueryDoCityResult,
    MealCityDictVal: state => state.mealQuery.MealCityDictVal,
    MealCityGPS: state => state.mealQuery.MealCityGPS,
    MealProvinceDictVal: state => state.mealQuery.MealProvinceDictVal,
    mettingType: state => state.mealQuery.mettingType,
    TakeoutCityDictVal: state => state.takeoutQuery.TakeoutCityDictVal,
    address: state => state.takeoutQuery.address,
    date: state => state.takeoutQuery.date,
    selectDate: state => state.takeoutQuery.selectDate,    
    time: state => state.takeoutQuery.time,
    tab: state =>  state.common.tab,
    shopList: state =>  state.takeoutQuery.shopList,
    meiCanParams: state =>  state.takeoutQuery.meiCanParams,
    requestShopList: state =>  state.takeoutQuery.requestShopList,
    takeoutProvince: state => state.common.takeoutProvince,
    takeoutCity: state => state.common.takeoutCity,
    mealProvince: state => state.common.mealProvince,
    mealCity: state => state.common.mealCity,
    hidddenQuery: state => state.common.hidddenQuery  
}
