import Vue from 'vue';
import ishopField from './ishopField/index';
import ishopScroll from './ishopScroll/index';

const components = [
	ishopField,
	ishopScroll
]
components.map((ele) => {
	Vue.use(ele);
})
export default components;