export default{
  toLowerCase: function (json) {
    for (var key in json) {
      var cache = json[key]
      delete (json[key])
      json[key.toLowerCase()] = cache
    }
    return json
  },
  arrPushArr: function (arr, arr2) {
    arr2.forEach(function (item) {
      arr.push(item)
    })
  },
  unique: function (arr) {
    var res = [arr[0]]
    for (var i = 1; i < arr.length; i++) {
      var repeat = false
      for (var j = 0; j < res.length; j++) {
        if (arr[i] === res[j]) {
          repeat = true
          break
        }
      }
      if (!repeat) {
        res.push(arr[i])
      }
    }
    return res
  }
}
