import { iStorage } from 'smart-core-util'
// import { iStorage } from '@/library/smart-core-util/src/index';
// var enviroment = process.env.NODE_ENV == 'production' ? 'PRD' : 'DEV'
var enviroment = process.env.NODE_ENV;
//var enviroment = 'DEV'
//    var enviroment = 'PRD'
console.log(enviroment)
const gateway = iStorage.getUrlGateway(),
      mShopUrl = iStorage.getUriSetting('webapp-mshop');  
var config = {
    'development': {
        baseURL: "https://a1wechat-gateway.roche.com.cn",
        logCollectUrl: "http://logcollect.dev.smartxcloud.cn/api/monitor/collect",
        uriGateway: "http://gateway-dev.smarteventcloud.cn",
        dictUrl: "http://dict.service.dev.smartxcloud.cn",
        gateway: gateway || "https://gateway.smarteventcloud.cn",
        mShopUrl: mShopUrl,
        meiCan: "https://iwaimai-webapp-dev.smarteventcloud.cn", //美餐微站地址
        ishop: "https://a1wechat-gateway.roche.com.cn"
    },
    'staging': {
        baseURL: "http://eventxapi.dev.c.smarteventcloud.cn",
        logCollectUrl: "http://logcollect.dev.smartxcloud.cn/api/monitor/collect",
        uriGateway: "http://gateway-stg.smarteventcloud.cn",
        dictUrl: "http://dict.service.dev.smartxcloud.cn",
        gateway: gateway || "https://gateway-dev.smarteventcloud.cn",
        mShopUrl: mShopUrl,
        meiCan: "https://iwaimai-webapp-dev.smarteventcloud.cn", //美餐微站地址
        ishop: "http://ag.container.smarteventcloud.cn"
    },
    'production': {
        baseURL: "https://a1wechat-gateway.roche.com.cn",
        logCollectUrl: "http://logcollect.smartxcloud.cn/api/monitor/collect",
        uriGateway: "http://ag.container.smarteventcloud.cn",
        dictUrl: "http://dict.service.smartxcloud.cn",
        gateway: gateway || "https://gateway.smarteventcloud.cn",
        mShopUrl: mShopUrl,
        meiCan: "https://iwaimai-webapp.smarteventcloud.cn", //美餐微站地址
        ishop: "https://a1wechat-gateway.roche.com.cn"
    }
}[enviroment]
console.log(config)
export default config