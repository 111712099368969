
import { miceService } from "@/service/miceService";
import {errorHandle2} from '@/utils/errorHandle'
export default {
    state: {
        MealCityGPS: null, 
        MealCityDictVal: '',
        MealProvinceDictVal: '',
        mettingType: {}
    },
    mutations: {
        'setMealCityDictVal':(state, MealCityDictVal) =>{
            state.MealCityDictVal = MealCityDictVal
        },
        'setMealParams':(state, params) =>{
            state[params.key] = params.value
        }                
    },
    actions: {
        GetOpenMealUrlPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.GetOpenMealUrl(data).then(res => {
                    errorHandle2(res)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },                      
    }
}