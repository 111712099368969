import toast from './toast'
export const errorHandle1 = (result) => {
    if(!result.status && !result.data&& result.msg) {
        toast(result.msg || '未知错误')
    }
}
export const errorHandle2 = (result) => {
    if(!result.success) {
        toast(result.msg || '未知错误')
    }
}