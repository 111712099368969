import {
    iStorage
} from 'smart-core-util'
import { miceService } from '../service/miceService'
import {
    getCookie
} from 'tiny-cookie';
export default class AppInitialization {
    static install() {
        this.setiDomain();
        this.urlParamToStorage();
        return new Promise(function (resolve) {
            let params = {
                collection: "cfg-ievent-initialization",
                filter: {
                    tenantId: getCookie('tenant_id'),
                    container: "h5"
                }
            }
            miceService.getsettings(params).then(res => {
                console.log('====app init==== 根据tenantid拿初始化参数', res.content)
                if (res && res.success && res.content) {
                    AppInitialization.setTheme(res.content.config.themeColors)
                    AppInitialization.setTitle(res.content.config.titleTxt)
                    AppInitialization.setUris(res.content.config.uris)
                    resolve(res)
                }
            })
            // }

        })
    }
    // 设置皮肤颜色
    static setTheme(colorList) {
        colorList.map(item => {
            document.body.style.setProperty(item.attribute, item.value)
            iStorage.set(item.attribute, item.value)
        })
    }
    // 设置title
    static setTitle(txt = "会引擎") {
        document.title = txt
    }
    // 设置Uris
    static setUris(uris = {}) {
        var tmp = { 'Uris': uris };
        sessionStorage.setItem("Uris", JSON.stringify(tmp))
    }

    // url 参数转 storage
    static urlParamToStorage() {

        let url = window.location.search.substring(1);
        if (url) {
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                iStorage.set(temp[0], temp[1]);
                console.log('====app init==== url参数转storage', item)
            })
        }
    }

    static setiDomain() {
        let domain = location.hostname;
        const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式 
        let isIP = re.test(domain);
        let isLocalhost = domain == "localhost";
        domain = (isIP || isLocalhost) ? domain : domain.substring(domain.indexOf('.') + 1);
        window.idomain = domain;
        console.log('====app init==== idomain', domain)
    }
}