//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		props: {
			allLoaded: {
				type: Boolean,
				default: false
			},
			topHeight: {
				type: Number,
				default: 0
			},
			bottomHeight: {
				type: Number,
				default: 0
			},
			pageIndex: {
//				type: String,
				default: 1
			},
			dataList: {
				type: Array,
				default: []
			},
			loadData: {
				type: Function
			}
		},
		data() {
			return {
				wrapperHeight: 0,
				listBoxHeight: 0,
				bottomStatus: '',
				topStatus: '',
				PageIndex:0
			}
		},
		mounted() {
			//计算滑动区域高度
			this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - (this.topHeight || this.$refs.wrapper.getBoundingClientRect().top) - this.bottomHeight;
			//计算滑动区域最小高度
			this.listBoxHeight = this.wrapperHeight - this.bottomHeight;
		},
		created() {
			this.PageIndex = this.pageIndex;
		},
		methods: {
			//上拉
			loadBottom() {
				this.PageIndex++;
				this.loadData(this.PageIndex)
				this.$nextTick(()=>{
					this.$refs.loadmore.onBottomLoaded();
				})
			},
			//下拉
			loadTop() {
				this.PageIndex = 1;
				this.loadData(this.PageIndex)
				this.$nextTick(()=>{
					this.$refs.loadmore.onTopLoaded();
				})
			},
			//下拉状态变化
			handleTopChange(status) {
				this.topStatus = status;
			},
			//上拉状态变化
			handleBottomChange(status) {
				this.bottomStatus = status;
			}
		}
	}
