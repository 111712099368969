import { iHttp } from '../core'
import MintUI from 'mint-ui'
import { indicator } from 'smart-indicator'
import config  from "./config"
import router from '../router/index'

const request = new iHttp({
    baseURL: config.baseURL,
    // baseURL: 'https://gateway.smarteventcloud.cn', //设置默认网关
    alert: MintUI.Toast, //error提示
    block: indicator.open,  //开启loading
    unblock: indicator.close,  //关闭loading
}).api;
const irequest = new iHttp({
    baseURL: config.baseURL,
    // baseURL: 'https://gateway.smarteventcloud.cn', //设置默认网关
    alert: MintUI.Toast, //error提示
    block: indicator.open,  //开启loading
    unblock: indicator.close,  //关闭loading
    handle401:function(){
        sessionStorage.removeItem('token');
        router.replace({
            name: 'Login'
        })
    }
}).api;

export const miceService = {
    /**
     * 公共
     */
    common: (url, params, conf) => {
        return request.post(url , params || {}, conf || {});
    },
    commonGet: (url, params) => {
        return request.get(url , {params: params});
    },
    getsettings: (params) => {
        return request.post(config.ishop + '/api/tpm/cfg/getsettings', params)
    },
    // mshop
    queryOpenedCities: (params) => {
        return request.get(config.gateway + "/api/mshop/Dictionary/QueryOpenedCities", {params: params})
    },
    mshopGet: (params) => {
        return request.get(config.gateway + "/api/mshop/shop/get", {params: params})
    },
    hospitalQuery: (params) => {
        return request.get(config.gateway + "/api/mshop/hospital/query", {params: params})
    },
    queryRegion: (params) => {
        return request.get(config.gateway + "/api/mshop/dictionary/QueryRegion", {params: params})
    },
    paging: (params) => {
        return request.get(config.gateway + "/api/mshop/shop/paging", {params: params})
    },
    search: (params) => {
        return request.get(config.gateway + "/api/mshop/shop/search", {params: params})
    },
    // qshop
    /*获取省份或城市*/
    QueryDoCity: (params) => {
        return request.post(config.uriGateway+"/foundation/dict/api/FnDictionary/Query", params)
    },
    /*获取会议类型*/
    GetMeetingTypes: (params) => {
        return request.post("/api/services/tenantx/TtProposalService/GetMeetingTypes", params)
    },
    /*获取送餐地点*/
    QueryAddresses: (params) => {
        return request.post("/api/services/external/MeiCanService/QueryAddresses", params)
    },
    /*获取送餐时间点*/
    QueryTimelist: (params) => {
        return request.post("/api/services/external/MeiCanService/QueryTimelist", params)
    },
    /*获取首页配置*/
    IndexConfig: (params) => {
        return request.post(config.uriGateway + "/api/resource/shop/Config/Get", params)
    },
    /*获取外部站点url*/
    GetOrderUrl: (params) => { 
        return request.post(config.uriGateway + "/api/category/meal/MeiTuan/GetOrderUrl", params)
    },
    /*获取餐厅站地址*/
    GetOpenMealUrl: (params) => {
        return request.get(config.gateway + "/api/Meal/Meal/GetOpenMealUrl", {params: params})
    },
    /*获取餐厅站地址*/
    getMenu: (params) => {
        return request.post("/api/services/external/MeiCanService/QueryShops", params)
    },
    //ishop
    /* 登录 */
    login: (params) => {
        return irequest.post(config.ishop + "/identity/login", params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    /* 获取国家type0省份type1城市type2 */
    getCityList: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/Region/QueryTenantRegions", params)
    },
    /*查询医院列表*/
    getHospitalList: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/Hospital/PagingTenantHospital", params)
    },
    queryDictionary: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/Dictionary/QueryDictionaryValView", params)
    },
    queryMyHospitals: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/ShopRecommend/QueryMyHospitals", params)
    },
    queryMyCity: (params) => { 
        return irequest.post(config.ishop + "/api/shopkeeper/ShopRecommend/QueryMyCity", params)
    },
    queryShops: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/ShopRecommend/QueryShops", params)
    },
    postMyShops: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/ShopRecommend/PostMyShops", params)
    },
    queryMyShops: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/ShopRecommend/QueryMyShops", params)
    },
    removeMyShop: (params) => {
        return irequest.post(config.ishop + "/api/shopkeeper/ShopRecommend/RemoveMyShop", params)
    },
    getTenantConfig: (params) => {
        return irequest.get(config.ishop + "/api/shopkeeper/ShopRecommend/GetTenantConfig", {params: params})
    },
    // 数据持久化-保存 queryCacheData
    saveCacheData: (params) => {
        let data = {
            ...params,
            Prefix: "jwts_"
        }
        return request.post(config.ishop + '/api/statestore/redis/create', data);
    },
    queryCacheData: (params) => {
        return request.post(config.ishop + '/api/statestore/redis/query', params);
    },
}