import Vue from 'vue';
import Router from 'vue-router';
import { MessageBox } from 'mint-ui';
import iRouters from './iRouters'
import store from '../store/store'
Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: iRouters
})

function jsonParse(jsonString) {
	if(jsonString && jsonString != "undefined") {
		return JSON.parse(jsonString)
	}
	return false;
}

function mutationTenantConfig() {
	var config = jsonParse(sessionStorage.getItem("tenantConfig")); //外出用餐、外卖相关配置信息
	var shopConfig = jsonParse(sessionStorage.getItem("shopConfig"));//医院附近餐厅条件
	var ishopMsg = jsonParse(sessionStorage.getItem("ishopMsg"));//提交推荐时提示语句
	var ishopRuleMsg = jsonParse(sessionStorage.getItem("ishopRuleMsg"));//医院上线基本规则
	if(config) {
		store.commit('setAllowTypes', config);
	}
	if(shopConfig) {
		store.commit('setShopParam', shopConfig);
	}
	if(ishopMsg) {
		store.commit('setShopMsg', ishopMsg);
	}
	if(ishopRuleMsg) {
		store.commit('setShopRuleMsg', ishopRuleMsg);
	}
}

//mutations store state from sessionstorage
router.beforeEach((to, from, next) => {
	if(to.name == "Login") {
		if(from.path != "/login") {
			next(false);
			MessageBox.confirm('是否确认退出?').then(action => {
				if(action == "confirm") {
					sessionStorage.clear();
					next();
					//关闭webview
					if(window.webView) {
						window.webView.close();
					}
				}
			}).catch(err => {
				console.log(err);
			});
		} else {
			sessionStorage.clear();
			next();
		}
	} else {
		if(to.meta.allowAnonymous) {
			const token = sessionStorage.getItem('token');
			if(!token && to.path != "/errorPage") {
				next({
					path: '/'
				});
				return;
			}
		}
		next();
	}
});
router.afterEach((to, from, next) => {
	if(to.meta.allowAnonymous) {
		mutationTenantConfig();
	}
});
export default router;
