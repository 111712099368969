//
//
//
//
//
//
//
//

	import { mapMutations, mapActions } from 'vuex'
	export default {
    name: 'App',
    watch: {
      '$route': 'getMyConfig'
    },
		async created() {
      this.getTheme()
      this.getMyConfig();  
		},
		methods: {
      getTheme() {
        //根据租户更换皮肤颜色
        let tenant = (this.iStorage.get("tenant_code") || "roche").replace(/\s/g,"");
        console.log(tenant);
        window.document.documentElement.setAttribute("data-theme", tenant);
      },
      getMyConfig: function () {
        var url = location.href.toLowerCase();
        this.filterSet('appType'); //涉及关闭qshop的方式(见closeWebview.js) appType分罗氏模式(如ievent)和cordova模式
				this.filterSet('token');
				this.filterSet('tenantId'); //租户id
				this.filterSet('queryType'); //查询类型(meal:餐厅查询,venue:酒店查询,car:车查询)
        //租户code
        this.filterSet('tenantcode');        
        //租户id保存
        this.filterSet('tenantid');
        // 保存GPS
        this.filterSet('nl');
        this.filterSet('el');
        this.filterSet('gpscity');
        //进入状态保存  判断是否为选择餐厅进入
        this.filterSet('action', 'search');
        //判断是否进入后直接跳转
        if (url.indexOf("goto=") != -1) {
          var nowUrl = url.split('goto=')[1];
          if (nowUrl.indexOf("&") != -1) {
            nowUrl = nowUrl.split("&")[0];
          }
          var newUrl = nowUrl.split("&");
          var trueUrl = decodeURIComponent(newUrl[0]);
          for (var i = 1; i < newUrl.length; i++) {
            trueUrl += '&' + newUrl[i];
          }
          location.href = trueUrl;
        }
      },
      filterSet: function (name, nor) {
        var url = location.href.toLowerCase();
        if (!sessionStorage.getItem(name) || sessionStorage.getItem(name) == 'undefined') {
          let trueData = '';
          if (url.indexOf('&' + name + '=') != -1) {
            trueData = url.split('&' + name + '=')[1];
            console.log(url.split('&' + name + '='))
            console.log(trueData)
          }
          if (trueData.indexOf('&') != -1) {
            trueData = trueData.split('&')[0];
            console.log(trueData)
          }
          if(name == 'gpscity') {
            trueData = decodeURIComponent(trueData);
          }
          sessionStorage.setItem(name, trueData);
          if (nor && !trueData) {
            sessionStorage.setItem(name, nor);
          }
        }
      },
			
		}
	}
