
import { miceService } from "@/service/miceService";
import { errorHandle1 } from '@/utils/errorHandle'
export default {
    state: {
        meiCanParams:'',
        shopList: [],
        TakeoutCityDictVal: '',
        address: {},
        time:{},
        requestShopList: false,
        selectDate: ""
    },
    mutations: {
        'setTakeoutCityDictVal':(state, TakeoutCityDictVal) =>{
            state.TakeoutCityDictVal = TakeoutCityDictVal
        },
        'setTakeoutParams':(state, params) =>{
            state[params.key] = params.value
        },        
        'setMeiCanParams': (state, meiCanParams) => {
            state.meiCanParams = meiCanParams
        },          
        'setShopList': (state, shopList) => {
            state.shopList = shopList
        },
        'setRequestShopList': (state, requestShopList) => {
            state.requestShopList = requestShopList
        }                
    },
    actions: {
        getMenuPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.getMenu(data).then(res => {
                    if (res && res.success && res.result) {
                        // commit('QueryDoCityResult', true)
                        let data = JSON.parse(res.result.data) || {}
                        console.log(data)
                        commit('setShopList', data.restaurantList)
                        errorHandle1(res.result)
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },                       
    }
}