import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import { iStorage } from './core'
import iJsBridge from 'smart-javascript-bridge'
import MintUI from 'mint-ui'
import common from './common/common.js'
import $ from 'jquery'
import config from '@/service/config'
import 'mint-ui/lib/style.css'
/**公共皮肤 */
import '@/assets/css/public.css'
import '@/assets/css/mint-ui.css'
import '@/assets/styles/index.scss'
import '@/assets/css/theme.scss'
//icon
import '@/assets/iconfont/iconfont.js' 

import "smart-filer/dist/smart-filer.min.css";
//vux控件
import { PopupPicker,Group } from 'vux';
Vue.component('popup-picker', PopupPicker)
Vue.component('group', Group)
//全局监听安卓返回
import '@/views/controllers/nativeJsBridge.js';

import './registerServiceWorker'
//注入指令
import '@/core/directives'
//注入过滤器
//引入bus
import Bus from './common/bus'
import '@/core/filter'
//注入全局组件
import './views/components/ishop/install.js';
//vux控件
//延时输入搜索调用方法
import { delaySearch } from './common/delaySearch.js';
import 'lib-flexible/flexible'
import { MessageBox } from 'mint-ui';
// 引入cookie
import * as tinyCookie from 'tiny-cookie';
import MHeader from '@/views/components/qshop/header.vue'
Vue.component('m-header', MHeader)
// iRem.install(document)
iStorage.install()
Vue.use(MintUI)
import validate from '@/utils/validate'
//toast
import toast from '@/utils/toast'
//webview
import Webview from '@/utils/webview' 

Vue.prototype.$ = $
Vue.prototype.Bus = Bus
Vue.prototype.$delaySearch = delaySearch
Vue.prototype.messageBox = MessageBox
Vue.prototype.common = common
Vue.prototype.iStorage = iStorage;
Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue();
Vue.prototype.$cookies = tinyCookie;
Vue.prototype.iJsBridge = new iJsBridge({ alert: MintUI.Toast });
Vue.prototype.validate = validate
Vue.prototype.toast = toast
Vue.prototype.webview = new Webview()
Vue.config.productionTip = false
Vue.prototype.config = config

import AppInitialization from "@/utils/app-initialization.js"
import CacheData from "@/utils/cache-data.js"
Promise.resolve().then(() => {
  return CacheData.install()
}).then(() => {
    return AppInitialization.install()
}).then(() => {
    console.warn('====正常启动App====')
    new Vue({
      router,
      store,
      render: h => h(App)//,
      // data: {
      //   $eventHub: new Vue()
      // }
    }).$mount('#app')
})
