import { miceService } from "@/service/miceService";
import { errorHandle1,  errorHandle2 } from '@/utils/errorHandle'
export default {
    state: {
        tab:'1',
        takeoutProvince: '',
        takeoutCity: '', 
        mealProvince: '',
        mealCity: '',  
        hidddenQuery: false              
    },
    mutations: {
        // ['QueryDoCityResult'](state, result) {
        //     state.QueryDoCityResult = result
        // },
        'setTab': (state, tab) =>{
            state.tab = tab
        },
        'setProvinceCity': (state, params) =>{
            state[params.key] = params.value
        },
        'setHiddenQuery': (state, value) => {
            state.hidddenQuery = value
        }

    },
    actions: {
        QueryDoCityPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.QueryDoCity(data).then(res => {
//                  if (res && res.success) {
//                      errorHandle1(res.result)
//                  }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        GetMeetingTypesPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.GetMeetingTypes(data).then(res => {
                    if (res && res.success) {
                        errorHandle1(res.result)
                    }                    
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        QueryAddressesPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.QueryAddresses(data).then(res => {
                    if (res && res.success) {
                        // commit('QueryDoCityResult', true)
                        errorHandle1(res.result)
                    }                    
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        QueryTimelistPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.QueryTimelist(data).then(res => {
                    if (res && res.success) {
                        errorHandle1(res.result)
                    }                    
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        IndexConfigPromise: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.IndexConfig(data).then(res => { 
                    errorHandle2(res)                   
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        //获取外部站点url
        GetOrderUrl: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                miceService.GetOrderUrl(data).then(res => { 
                    //errorHandle2(res)                   
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        } 
    }
}