function filterData(data, key, val) {
	var result = data.filter(item => {
		return item[key] == val
	})
	return result;
}

function exist(arr) {
	return arr && arr.length > 0;
}

function filterMaxQty(data, type) {
	var returnData = {
		type1: 0,
		type2: 0,
		type3: 0,
		type4: 0,
	};
	for(var i = 0; i < data.length; i++) {
		//外出
		if(type == 5) {
			if(data[i].type == 1) {
				returnData.type1 = data[i].maxQty;
			}
			if(data[i].type == 2) {
				returnData.type2 = data[i].maxQty;
			}
		}
		//外卖
		if(type == 7) {
			if(data[i].type == 2) {
				returnData.type2 = data[i].maxQty;
			}
			if(data[i].type == 3) {
				returnData.type3 = data[i].maxQty;
			}
			if(data[i].type == 4) {
				returnData.type4 = data[i].maxQty;
			}
		}
	}
	return returnData;
}

export default {
	state: {
		pass: '', //进入该view的通道
		allowItemType7: false, //外卖
		allowItemType5: false, //堂食
		allowRecommendType2: false, //手录
		allowRecommendType1: false, //选择
		ishopParam: {}, //医院附近餐厅条件
		meesageRule: '', //提交推荐时提示语句
		shopRuleMsg: '', //医院上线基本规则
		itemType5: {}, //
		itemType7: {},
		maxQty5: 0,
		maxQty5Type: 0,
		maxQty7Type2: 0,
		maxQty7Type3: 0,
		maxQty7Type4: 0,
		isDisable: false,
		provinceForm: {
			pingRegionId:'',
			regionId:'',
			txt:''
		},
		cityForm:{
			pingRegionId:'',
			regionId:'',
			txt:''
		},
	},
	mutations: {
		SETPROVINCEFORM(state, value){
			if(!value){
				for(var key in state.provinceForm){
					state.provinceForm[key] = '';
				}
				return false;
			}
			for(var key in value){
				state.provinceForm[key] = value[key];
			}
		},
		SETCITYFORM(state, value){
			if(!value){
				for(var key in state.cityForm){
					state.cityForm[key] = '';
				}
				return false;
			}
			for(var key in value){
				state.cityForm[key] = value[key];
			}
		},
		setPass(state, config) {
			state.pass = config
		},
		changeisDisable(state, config) {
			state.isDisable = config
		},
		setAllowTypes(state, config) {
			//外出用餐    itemType == 5
			state.itemType5 = filterData(config, 'itemType', 5);
			//外卖  itemType == 7
			state.itemType7 = filterData(config, 'itemType', 7);
			//存在外出用餐配置
			if(state.itemType5.length > 0) {
				let max5 = filterMaxQty(state.itemType5[0].recommendTypes, 5);
				//外出用餐 -- 【选择/最大数】
				state.maxQty5 = max5.type1;
				//外出用餐 -- 【手工录入/最大数】
				state.maxQty5Type = max5.type2;
				//是否存在 外出用餐配置  true/false
				state.allowItemType5 = exist(filterData(config, 'itemType', 5)); //此处判断多余，外层if判断已经确定length > 0 TODO
			}
			//存在外卖配置
			if(state.itemType7.length > 0) {
				let max7 = filterMaxQty(state.itemType7[0].recommendTypes, 7)
				//外卖--手工录入/最大数
				state.maxQty7Type2 = max7.type2;
				//外卖--外卖/最大数
				state.maxQty7Type3 = max7.type3;
				//外卖--茶歇/最大数
				state.maxQty7Type4 = max7.type4;
				//是否存在 外卖配置  true/false
				state.allowItemType7 = exist(filterData(config, 'itemType', 7)); //此处判断多余，外层if判断已经确定length > 0 TODO
			}
			// let max5 = filterMaxQty(config[0].recommendTypes, 5);
			// let max7 = filterMaxQty(config[0].recommendTypes, 7)
			if(state.allowItemType5) {
				//外出用餐--是否有手工录入配置项
				state.allowRecommendType2 = exist(filterData(state.itemType5[0].recommendTypes, 'type', 2))
				//外出用餐--是否有选择配置项
				state.allowRecommendType1 = exist(filterData(state.itemType5[0].recommendTypes, 'type', 1))
				// state.meesageRule = '您推荐的餐厅已提交成功。<br/>我们会对您推荐的餐厅进行上线审核：<br/>1、距离医院3km内<br/>2、能够开具正规发票<br/>3、同意发票暂存在餐厅<br/>4、有包房或独立隔断区域<br/>5、仅接受机打水单或小票'
			} else {
				// state.meesageRule = '您推荐的餐厅已提交成功。<br/>在调查截止日之前，可以继续编辑修改'
			}

		},
		setShopParam(state, shopConfig) {
			state.ishopParam = shopConfig
		},
		setShopMsg(state, setShopMsg) {
			state.meesageRule = setShopMsg
		},
		setShopRuleMsg(state, shopRuleMsg) {
			state.shopRuleMsg = shopRuleMsg
		}
	}
}
