/**
 * loading状态管理
 * 
 * @author  <nianhui.qi>
 * 
 */
import * as types from '@/store/rooter-mutation-types'
export default {
  state: {
    event: {},
  },
  mutations: {
    [types.EVENT]: (state, event) => {
      state.event = event
    }   
  },
  actions: {

  }
}