const mShop = [{
    path: '/error',
    name: 'Error',
    component: resolve => require(["@/views/common/mshop/error.vue"], resolve),
  }, {
    path: '/index',
    name: 'Index',
    component: resolve => require(["@/views/common/mshop/index.vue"], resolve),
  }, {
    path: '/cities',
    name: 'Cities',
    component: resolve => require(["@/views/common/mshop/cities.vue"], resolve),
  }, {
    path: '/hospitalList',
    name: 'hospitalList',
    component: resolve => require(["@/views/common/mshop/hospitals.vue"], resolve),
  }, {
    path: '/detail',
    name: 'Detail',
    component: resolve => require(["@/views/common/mshop/detail.vue"], resolve),
  }, {
    path: '/search',
    name: 'Search',
    component: resolve => require(["@/views/common/mshop/search.vue"], resolve),
  }, {
    path: '/map/',
    name: 'Map',
    component: resolve => require(["@/views/common/mshop/map.vue"], resolve),
  }, {
    path: '/selectAddress/',
    name: 'selectAddress',
    component: resolve => require(["@/views/common/mshop/selectAddress.vue"], resolve),
  }]

const qShop = [{
  path: '/',
  component: resolve => require(["@/views/common/qshop/index.vue"], resolve),
}, {
  path: '/defaultIndex',
  name: 'defaultIndex',
  component: resolve => require(["@/views/common/qshop/defaultIndex.vue"], resolve),
}, {
  path: '/selectCity',
  name: 'SelectCity',
  component: resolve => require(["@/views/common/qshop/selectCity.vue"], resolve),
}, {
  path: '/takeOutShopList',
  name: 'takeOutShopList',
  component: resolve => require(["@/views/common/qshop/takeOutShopList.vue"], resolve),
}, {
  path: '/takeOutShopDetail',
  name: 'takeOutShopDetail',
  component: resolve => require(["@/views/common/qshop/takeOutShopDetail.vue"], resolve),
}, {
  path: '/mShop',
  name: 'mShop',
  component: resolve => require(["@/views/common/qshop/mShop.vue"], resolve),
}, {
  path: '/takeoutQueryIndex',
  name: 'takeoutQueryIndex',
  component: resolve => require(["@/views/common/qshop/takeoutQueryIndex.vue"], resolve),
}, {
  path: '/commonQueryIndex',
  name: 'commonQueryIndex',
  component: resolve => require(["@/views/common/qshop/commonQueryIndex.vue"], resolve),
}]

const iShop = [{
  path: '/login',
	name: 'Login',
	component: resolve => require(["@/views/common/ishop/login.vue"], resolve),
}, {
  path: '/chooseType',
	name: 'ChooseType',
  component: resolve => require(["@/views/common/ishop/chooseType.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/myShops',
	name: 'MyShops',
  component: resolve => require(["@/views/common/ishop/tangshi/myShops.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/myTakeaway',
	name: 'MyTakeaway',
  component: resolve => require(["@/views/common/ishop/waimai/myTakeaway.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/ishop/search',
	name: 'IshopSearch',
  component: resolve => require(["@/views/common/ishop/search.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/step0tangshi',
	name: 'Step0tangshi',
  component: resolve => require(["@/views/common/ishop/tangshi/step0tangshi.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/step0waimai',
	name: 'Step0waimai',
  component: resolve => require(["@/views/common/ishop/waimai/step0waimai.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/step1SelectShop',
	name: 'Step1SelectShop',
  component: resolve => require(["@/views/common/ishop/tangshi/step1SelectShop.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/step1Input',
	name: 'Step1Input',
  component: resolve => require(["@/views/common/ishop/waimai/step1Input.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/step2Input',
	name: 'Step2Input',
  component: resolve => require(["@/views/common/ishop/waimai/step2Input.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/step2CustomShop',
  name: 'Step2CustomShop',
  component: resolve => require(["@/views/common/ishop/tangshi/step2CustomShop.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/ishop/map',
  name: 'IshopMap',
  component: resolve => require(["@/views/common/ishop/map.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/errorPage',
  name: 'errorPage',
  component: resolve => require(["@/views/common/ishop/errorPage.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}, {
  path: '/city',
  name: 'city',
  component: resolve => require(["@/views/common/ishop/Select/city.vue"], resolve),
}, {
  path: '/hospital',
	name: 'hospital',
  component: resolve => require(["@/views/common/ishop/Select/hospital.vue"], resolve),
  meta: {
		allowAnonymous: true
	}
}]

const iRouters = [...mShop, ...qShop, ...iShop]

export default iRouters;