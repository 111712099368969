import Vue from 'vue'
import Vuex from 'vuex'

import rooter from './module/rooter'
import common from './module/common'
import mealQuery from './module/mealQuery'
import takeoutQuery from './module/takeoutQuery'
import ishop from './ishop'

import getters from './getters'
import createLogger from 'vuex/dist/logger'
// const debug = process.env.NODE_ENV !== 'production'
const debug = false
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: {
        common,
        rooter,
        mealQuery,
        takeoutQuery,
        ishop
    },
    getters,
    strict: false,
    plugins: debug ? [createLogger()] : []
})

export default store
