// import utilsV2 from '../utilsV2.js'
// import toast from '../toast/f7Toast.js'
// var myApp = new Framework7()
import config from '@/service/config'

var Webview = function() {}
var browser = null
Webview.prototype.open = function(url, listeners) {
    try {
        // console.log('url->' + url)
        // var options = 'location=no,clearcache=yes,EnableViewPortScale=yes,zoom=no,toolbar=no,keyboardDisplayRequiresUserAction=yes,hidden=no,disallowoverscroll=yes,statusbarcollor=#0099ff'
        var options = ''
        options = 'location=no,clearcache=yes,EnableViewPortScale=yes,zoom=no,toolbar=no,keyboardDisplayRequiresUserAction=yes,hidden=no,disallowoverscroll=yes'
        options = options + ",logcollecturl=" + config.logCollectUrl + ",apptype=Novartis";

        if (url.indexOf('support') > 0) {
            var device = Framework7.prototype.device;
            if (device.ios) {
                // console.log('this is iPhone')
                // options = 'location=no,clearcache=yes,EnableViewPortScale=yes,zoom=no,toolbar=no,toolbarposition=top,keyboardDisplayRequiresUserAction=yes,hidden=no,disallowoverscroll=yes,closebuttoncaption=关闭,closebuttoncolor=#E1F6F6,toolbarcolor=#0099ff,titlecolor=#E1F6F6,showpagetitle=yes'
            } else if (device.android) {
                // console.log('this is android')
                // options = 'location=yes,clearcache=yes,EnableViewPortScale=yes,zoom=no,toolbar=no,keyboardDisplayRequiresUserAction=yes,hidden=no,disallowoverscroll=yes,closebuttoncaption=关闭,closebuttoncolor=#E1F6F6,toolbarcolor=#0099ff,titlecolor=#E1F6F6,showpagetitle=yes'
            }
        }
        // if (cordova.plugins) {
        // }
        var rnd = (new Date()).getTime()
        var _url = url + '?&rnd=' + rnd
        if (url.indexOf("?") > 0) {
            _url = url + '&rnd=' + (new Date()).getTime()
        }
        browser = cordova.InAppBrowser.open(_url, '_blank', options)

        browser.addEventListener('loadstart', function(result) {
            console.log('=======loadstart======' + JSON.stringify(result))
            if (listeners['loadstart']) {
                listeners['loadstart'](result)
            }
        })
        browser.addEventListener('loadstop', function(result) {
            // console.log('=======loadstop======' + JSON.stringify(result))
            if (listeners['loadstop']) {
                listeners['loadstop'](result)
            }
        })
        browser.addEventListener('loaderror', function(result) {
            console.log('webview loaderror' + JSON.stringify(result))
            if (listeners['loaderror']) {
                listeners['loaderror'](result)
            }
        })
        browser.addEventListener('receivedData', function(data) {
            console.log('receivedData->' + JSON.stringify(data))
            if (listeners['receivedData']) {
                var handler = data.handler
                var viewData = JSON.parse(data.data)
                listeners['receivedData'](handler, viewData)
            }
        })
    } catch (e) {
        console.log('open webview->' + JSON.stringify(e))
    }

}
Webview.prototype.close = function() {
        browser.close()
    }
    // module.exports = new Webview()
export default Webview