//
//
//
//
//
//

    export default {
        props: {
            back: {
                type: Boolean,
                default: true
            },
            title: {
                required: true,
                type: String
            },
            subText: {
                type: String,
                default: '提交'
            },
            isRightSubmit: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            backHandle () {
                this.$router.back()
            },
            submitHandle () {
                this.$emit('submitHandle')
            }
        }
    }
