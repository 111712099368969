//
//
//
//
//
//
//
//
//
//
//
//
//
//

	export default {
		props: {
			label: {
				type: String,
				default: ''
			},
			data: {
				type: String,
				default: ''
			},
			verify: {
				type: Boolean,
				default: false
			},
			hasIcon: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {

		}
	}
